@import '@/assets/style/default';

.component-banner{
    
    &::before{
        content: '';
        width: 100%;
        height: 70px;
        display: block;
    }
    

    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        svg {
            position: absolute;
            width: 100%;
            height: auto;
            z-index: 2;
            top: 0;
            right: 0;

            #group{
                // fill:$bg-fill;
                fill:url(#header-shape-gradient);

            }
        }

        @media screen and (max-width: 767px) {
            overflow: hidden;
            display: none;
            top: 70px;
            svg {
                transform: scale(2) translateX(-100px);
            }
        }
    }

    .computer{
        position: absolute;
        top: 15%;
        right: 10%;
        width: 100%;
        max-width: 500px;
        height: auto;
        z-index: 10;
        // display: none;

        
        > img {
            display: block;
            width: 100%;
            max-width: 500px;
        }

        > .content-cell{
            position: absolute;
            top: 15%;
            right: 22%;
            width: 120px;
            transform: skewX(-15deg);
            mix-blend-mode: multiply;

            > img{
                width: 100%;
                height: auto;
                display: block;
            }
        }

        @media screen and (min-width: 1601px) {
            > .content-cell{
                width: 200px;
            }
        }

        @media screen and (max-width: 1600px) {
            max-width: 450px;
            > img{
                max-width: 450px;
            }
        }

        @media screen and (min-width: 1024px ) and (max-width: 1599px) {
            right: 5%;
            top: 20%;
            max-width: 300px;
            > img{
                max-width: 300px;
            }
        }

        @media screen and (min-width: 768px) and (max-width: 1023px ) {
            max-width: 300px;
            right: 2%;
            top: 20%;
            > img{
                max-width: 300px;
            }
        }

        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    .content{
        width: 100%;
        max-width: 1024px;
        height: auto;
        position: relative;
        z-index: 3;

        font-size: 2em;
        font-weight: 400;

        @media screen and (max-width: 767px) {
            font-size: 1.5em;
        }

        > div {
            width: 60%;
            margin: 10px 0;

            @media screen and (max-width: 767px) {
                width: 90%;
                margin: 10px auto;
            }
        }

        .resume{
            font-size: 0.65em;
            font-weight: 400;
            margin-top: 20px;

        }

        span{
            font-weight: 900;
            font-size: 1.8em;
            text-transform: uppercase;

            &.color-1{
                color: $color-base-blue;
            }

            &.color-2{
                color: $color-base-purple-light;
            }

            &.color-3{
                color: $color-base-green;
            }

            &.color-4{
                color: $color-base-red;
            }

            &.color-5{
                color: $color-base-orange;
            }

        }

        .buttons{
            margin-top: 40px;

            > .button{
                background-color: $color-base-orange;
                color: #FFF;
                font-size: 0.8em;
                display: inline-block;
                box-sizing: border-box;
                padding: 10px 20px;
                border-radius: 10px;
                cursor: pointer;
            }
        }

    }

    .button-scroll{
        position: absolute;
        left: 0;
        bottom: 100px;
        width: 100%;
        height: 32px;
        min-height: 32px;
        z-index: 10;
        

        .button{
            width: 30px;
            height: 30px;
            margin: 10px auto;
            animation: scrolldown 1s infinite linear;

            @keyframes scrolldown {
                0%, 100%{
                    transform: translateY(0);
                }

                50%{
                    transform: translateY(-5px);
                }
            }

            &::before{
                content: '';
                width: 6px;
                height: 30px;
                background-color: $color-base-orange;
                display: inline-block;
                transform:rotate(-45deg) ;
                transform-origin: center center;
                position: relative;
                left: -6px;
                border-radius: 50% 50% 3px 3px;
            }

            &::after{
                content: '';
                width: 6px;
                height: 30px;
                background-color: $color-base-orange;
                display: inline-block;
                transform: rotate(45deg);
                transform-origin: center center;
                position: relative;
                right: -6px;
                border-radius: 50% 50% 3px 3px;
            }
        }
    }

    @media screen and (max-width: 767px) {
        background-image: url(../../../assets/image/banner_home.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        color: #FFF;
        &::before{
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
        }
    }
}