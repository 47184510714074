@import '@/assets/style/default';

.page-home{
    width: 100%;
    height: auto;
    min-height: 100vh;
    position: relative;
    box-sizing: border-box;
    overflow-x: hidden;
    background-color: rgba(255,255,255, 1);

    .menu{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
        z-index: 1000;
        box-sizing: border-box;
    }


    .banner{
        width: 100%;
        height: 100vh;
        position: relative;
        z-index: 1;
        
    }

    .content{
        box-sizing: border-box;
        padding: 20px;
        position: relative;
        width: 100%;
        max-width: 1024px;
        height: auto;
        margin: 0 auto;

        .available{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 15px;
            align-items: flex-start;
            justify-content: center;
            min-height: 100px;
            height: auto;

            @media screen and (max-width: 767px) {
                grid-template-columns: 1fr;
                row-gap: 15px;
            }

            > div{

                .title{
                    text-align: center;
                    font-size: 1.25em;
                    font-weight: 800;
                    color: $color-base-blue;
                    box-sizing: border-box;
                    padding: 10px 0;
                }

                .icon{
                    img{

                        display: block;
                        width: 100%;
                        max-width: 150px;
                        height: auto;
                        margin: 5px auto;
                    }
                }

                .text{
                    box-sizing: border-box;
                    padding: 5px 10px;
                    text-align: center;

                    @media screen and (max-width: 767px) {
                        padding: unset;
                    }
                }
            }
        }

        .text-about-us{
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 15px;
            row-gap: 15px;
            align-items: center;

            .image{
                max-width: var(--max-image);
                min-width: 250px;
                box-sizing: border-box;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                > img{
                    display: block;
                    width: 100%;
                    height: auto;

                    &.image-filter{
                        animation: image-filter linear infinite 10s;

                        @keyframes image-filter {
                           0%{
                            filter: hue-rotate(0deg);
                            
                           }
                           
                           100%{
                            filter: hue-rotate(360deg);
                           }
                        }
                    }
                }
            }

            @media screen and (max-width: 767px) {
                grid-template-columns: 1fr;
                
                .image{
                    width: 100%;
                    min-width: 100%;
                    grid-row: 1;
                }
            }

            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr;
                column-gap: unset;

                .image{
                    width: 100%;
                    min-width: 100%;
                    grid-row: 1;
                    text-align: center;
                }
            }
        }

        &.images{
            background-color: $color-base-green;
            background-image: url(../../../assets/image/photo-bg.jpg);
            background-size: cover;
            background-position: center center;        
            background-attachment: fixed;
            background-repeat: no-repeat;
            box-sizing: border-box;
            padding: 20px;
            max-width: unset;
            position: relative;
            display: flex;
            align-items: center;
            min-height: 500px;


            &::before{
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(45deg, $color-base-purple-dark, $color-base-red);
                opacity: 0.7;
            }

            .section-images{
                width: 100%;
                max-width: 1024px;
                margin: 0 auto;
                display: grid;
                grid-template-columns: 1fr;

            }
        }

        &.video{
            background-color: $color-base-green;
            background-image: url(../../../assets/image/photo-bg.jpg);
            background-size: 100% 100%;
            background-position: center center;        
            background-attachment: fixed;
            background-repeat: no-repeat;
            box-sizing: border-box;
            padding: 20px;
            max-width: unset;
            position: relative;
            display: flex;
            align-items: center;
            min-height: 500px;


            &::before{
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(45deg, $color-base-purple-light, $color-base-orange);
                opacity: 0.6;
            }

            .section-video{
                width: 100%;
                max-width: 1024px;
                margin: 0 auto;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                color: #FFF;
                z-index: 2;
                position: relative;
                font-size: 1.8em;
                height: 100%;
                align-items: center;
                row-gap: 15px;
                column-gap: 15px;

                .video:empty::before{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: $color-base-blue-dark;
                    min-width: 460px;
                    min-height: 320px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 1.5em;
                }

                @media screen and (max-width: 768px) {
                    grid-template-columns: 100%;
    
                    .video:empty::before{
                        width: 100%;
                        height: 280px;
                        min-width: unset;
                        min-height: unset;
                    }

                    .video{
                        margin-top: 15px;
                    }
                }

                @media screen and (max-width: 400px) {
                    font-size: 1.2em;
                }
            }
        }

        .section-title{
            font-size: 2em;
            font-weight: 800;
            color: $color-base-blue;
            box-sizing: border-box;
            padding: 10px 0;
            text-align: center;
            margin: 0 auto 20px;
            position: relative;
            display: inline-block;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
           
            &::before{
                content: '';
                display: block;
                width: 110%;
                height: 10px;
                position: absolute;
                bottom: 5px; 
                left: 50%;
                transform: translate(-50%, 5%);
                border-top: 1px dashed $color-base-green;
                z-index: 1;
            }
        }


        .section-content{
            width: 100%;
            max-width: 1024px;
            margin: 0 auto;

        }

        &.newsletter{
            background-color: $color-base-purple-dark;
            max-width: 100%;
            color: #FFF;
            box-sizing: border-box;
            padding: 30px;
            border: 0;
            position: relative;

            > svg{
                position: absolute;
                width: 400px;
                top: -60%;
                left: -5%;
                display: none;
                path{
                    fill: $color-base-purple-dark;
                    filter: brightness(2);
                }

            }

            > .section-content{
                position: relative;
                z-index: 10;
            }

            .section-title{
                color: #FFF;
                text-align: left;
                left: unset;
                transform: unset;

                &::before{
                    display: none;
                }
            }

            .form-newsletter{
                display: grid;
                grid-template-columns: 1fr 1fr 100px;
                row-gap: 15px;
                column-gap: 15px;
                box-sizing: border-box;
                padding: 10px 0;

                
        @media screen and (max-width: 767px) {
            grid-template-columns: 1fr;
        }

                input{
                    width: 100%;
                    height: 32px;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 10px;
                    border: 0;
                }

                button{
                    width: 100%;
                    height: 32px;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 10px;
                    border: 0;
                    background-color: #FFF;
                    color: $color-base-gray;
                }

            }
        }
    }

    footer{
        background-image: linear-gradient(to bottom, $color-base-purple-dark ,$color-base-blue-dark);
        width: 100%;
        height: auto;
        min-height: 100px;
        position: relative;
        box-sizing: border-box;
        padding: 40px 20px;
        color: #FFF;
  
    }

}
