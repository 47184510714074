@import '@/assets/style/default';

.component-gallery{
    width: 90%;
    max-width: 1024px;
    display: grid;
    grid-template-columns: repeat(4, minmax(240px, auto));
    row-gap: 10px;
    column-gap: 10px;
    position: relative;
    z-index: 3;

    @media screen and (max-width: 768px) {
        width: 100%;
        grid-template-columns: 1fr 1fr;
    }

    > .card{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 200px;
        width: 100%;
        min-width: 200px;
        grid-row: auto / span var(--row);
        grid-column: auto / span var(--collumn);
        color: #FFF;
        position: relative;
        overflow: hidden;
        transition: all ease 300ms;
        box-sizing: border-box;
        padding: 1px;

        @media screen and (max-width: 767px) {
            width: 100%;
            min-width: 100%;
        }

        .image{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-position: center center;
            background-image: var(--image);
        }
    }
}