@import '@/assets/style/default';


.component-prices{

    width: 100%;
    box-sizing: border-box;
    padding: 10px;

    .area-products{
        
        width: 96%;
        max-width: 1024px;
        margin: 0 auto;
        display: grid;

        grid-template-columns: repeat(3, 1fr); 
        column-gap: 15px;

        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            row-gap: 15px;
            column-gap: unset;
        }
        
        .product{
            box-sizing: border-box;
            padding: 20px;

            .icon-card{
                width: 90%;
                box-sizing: border-box;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;

                > img{
                    width: 100%;
                    max-width: 150px;
                    display: block;
                    margin: 0 auto;
                }
                
            }

            .title{
                font-weight: 800;
                text-align: center;
                color: $color-base-blue;
                font-size: 1.25em;
            }

            .text{
                margin-top: 10px;
            }
        }
    }

    .especial-cases{
        display: grid;
        grid-template-columns: 150px auto;
        align-items: center;
        overflow: hidden;

        .icon{
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            img{
                width: 100%;
                max-width: 150px;
                display: block;
            }
        }

        .text{
            box-sizing: border-box;
            padding: 20px;
            color: #FFF;
            background-color: $color-base-green;
            font-weight: 600;
            font-size: 1.2em;

        }

        @media screen and (max-width: 767px) {
            grid-template-columns: 1fr;
            row-gap: 15px;
            column-gap: unset;

            .text{
                font-size: 1em;
                text-align: center;
            }
        }
    }

    .see-all{
        text-align: center;
        color: $color-base-gray;
        margin-top: 15px;
        font-weight: 800;
        box-sizing: border-box;
        padding: 20px 10px;

        .click-here{
            cursor: pointer;
            color: $color-base-blue;
            text-decoration: underline;
        }
    }
}