@import '@/assets/style/default';

.component-footer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 15px;
    row-gap: 15px;
    width: 100%;
    max-width: 1024px;
    margin: 10px auto;

    > div {
        .name{
            font-weight: 800;

            + div > small{
                font-weight: 100;
            }
        }

        &.social-media{
            display: grid;
            grid-template-columns: repeat(6, 32px);
            justify-content: flex-end;
            column-gap: 10px;

            div{
                min-width: 32px;
                min-height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all ease 300ms 300ms;

            }

            img{
                width: 100%;
                height: auto;
                display: block;
            }

            &:hover div:not(:hover){
                filter: grayscale(1);
                transform: scale(0.8);
            }

            &:hover div{
                filter: grayscale(0);
                transition: all ease 600ms 100ms;
                transform: scale(1);
            }

        }
    }

    > div:last-child{
        font-size: 0.8rem;
        text-align: center;
        grid-column: 1 / span 3;
        box-sizing: border-box;
        padding: 10px;
        margin-top: 15px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: auto;

        > div:last-child{
            grid-column: unset;
        }
    }
}