@import '@/assets/style/default';

.component-menu-home{

    display: grid;
    grid-template-columns: 230px 600px;
    box-sizing: border-box;
    padding: 10px;
    align-items: center;

    .logo{
        img{
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .menu{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;

        #bt-menu{
            opacity: 0;
            position: absolute;
            pointer-events: none;

            > label {
                display: none;
            }
        }

        ul{
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, auto));
            
            padding: 0;
            margin: 0;
            justify-content: flex-end;
            align-items: center;

            li {
                list-style: none;
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                height: 100%;
                a{
                    color: #FFF;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                }
            }
        }
    }

    background-image: linear-gradient(to bottom, #FFF 100%, transparent 100%);
    background-size: 100% 200px;
    background-position: 0 -200px;
    background-repeat: no-repeat;
    transition: background linear 200ms, box-shadow linear 200ms, color linear 200ms;
    box-shadow: 0 0 0 $color-base-gray;

    &.fixed{
        background-image: linear-gradient(to bottom, #FFF 100%, transparent 100%);
        background-position: 0 0px;
        transition: background linear 300ms, box-shadow linear 300ms 300ms, color linear 300ms;
        box-shadow: 0 0 5px $color-base-gray;

        .menu {
            ul{
                li a{
                    color: $color-base-blue;
                    align-items: flex-end;
                    cursor: pointer;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        background-color: #FFF;
        box-shadow: 2px 0 5px $color-base-gray;

        .menu {

            position: relative;

            #bt-menu{
                opacity: 0;
                position: absolute;
                pointer-events: none;
            }

            label{
                display: flex;
                width: 32px;
                height: 32px;
                position: absolute;
                right: 20px;
                top: 50%;
                flex-direction: column;

                align-items: center;
                justify-content: center;

                > div {
                    background-color: $color-base-gray;
                    width: 90%;
                    height: 4px;
                    margin: 0 auto;
                    display: block;
                    position: relative;
                    transition: all ease 300ms;
                    transform-origin: center center;
                    border-radius: 2px;

                    + div{
                        margin-top: 5px;
                    }
                }
            }

            #bt-menu:checked + label{
                display: flex;
                width: 32px;
                height: 32px;
                align-items: center;
                justify-content: center;

                > div{
                    transition: all ease 600ms;
                    position: absolute;
                }

                > div:nth-child(1){
                    transform: rotate(45deg);
                }

                > div:nth-child(3){
                    transform: rotate(-45deg);
                }

                > div:nth-child(2){
                    opacity: 0;
                }

                
            }

            ul{
                position: fixed;
                z-index: 100;
                top: 105px;
                background-color: rgba(255, 255, 255, 0.9);
                height: 100%;  
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(5, 40px);
                opacity: 0;
                pointer-events: none;

                li a{
                    color: $color-base-gray;
                    justify-content: flex-end;
                    box-sizing: border-box;
                    padding: 5px 20px;

                }
            }

            #bt-menu:checked + label + ul {
                opacity: 1;
                transition: all ease 600ms;
                pointer-events: auto;
                
            }
        }
    }
}