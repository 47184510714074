@import 'default';

@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Text&family=Roboto:wght@100;400;700&display=swap');

html{

    width: 100%;
    //min-width: 100vw;
    height: auto;
    min-height: 100vh;
    overflow-x: hidden;

    body{
        background-color: #ccc;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        box-sizing: border-box;
        
        font-family: 'Roboto', sans-serif;
        color: $color-base-gray;

        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;

        #root{
            box-sizing: border-box;
            padding: 10px;
            width: 100%;
            min-width: 100vw;
            height: auto;
            min-height: 100vh;
            margin: 0;
            padding: 0;
            overflow-x: hidden;
        }

    }
}