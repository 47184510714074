@import '@/assets/style/default';

.component-contact{
    width: 100%;
    
    .area-form{
        width: 100%;
        margin: 0 auto;

        form{
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 15px;
            row-gap: 15px;

            
            > div{
                width: 100%;

                > input, textarea, select {
                    width: 100%;
                    border: 0px solid #ccc;
                    border-radius: 0px;
                    box-sizing: border-box;
                    padding: 15px;
                    background-color: #fff;
                    border-bottom: 1px solid #ccc;
                }

                > textarea{
                    resize: none;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }

                select{
                    -webkit-appearance: none;
                    color: #999;
                    position: relative;
                    

                    option, slot{
                        border: none;
                        background-color: #fff;
                        outline: 0;
                        box-shadow: unset;
                    }

                }


                &.description{
                    grid-column: 1 / span 2;
                }

                &.send-message{
                    grid-column: 1 / span 2;
                    display: flex;
                    justify-content: flex-end;
                    border: 0;
                    border-radius: 4px;

                    button{
                        box-sizing: border-box;
                        padding: 10px 20px;
                        background-color: $color-base-blue-dark;
                        color: #fff;
                        border: 0;
                        border-radius: 4px;
                    }
                }
            }

            @media screen and (max-width: 767px) {
                grid-template-columns: auto;

                > div{
                    &.description, &.send-message{
                        grid-column: unset;
                    }   
                }
            }
        }
    }

}